import React from "react"
import '../styles/Contact.css'
import Navbar from "../Navbar"
import { addRecord } from "../../config"

const Contact = () => {
    const handleSubmit = (e) => {
        e.preventDefault()
        var formData = {
            name: document.querySelector('.input-name').value,
            email: document.querySelector('.input-email').value,
            phone: document.querySelector('.input-number').value,
            message: document.querySelector('.input-message').value
        }
        try {
            addRecord(formData, 'contact')
        } catch (e) {
            console.log(e, "Error adding record to database.")
        }
        document.querySelector('.contact-form').reset()
    }

    return (
        <div>
            <Navbar />
            <div className="contact-main-container">
                <div className="contact-content-container">
                    Contact Us
                </div>
                <div className="contact-form-container">
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <div className="input-data">
                            <input type="text" required className="input-name" placeholder="Full Name" />
                        </div>
                        <div className="input-data">
                            <input type="number" required className="input-number" placeholder="Contact Number" maxLength={10} minLength={10} />
                        </div>
                        <div className="input-data">
                            <input type="email" required className="input-email" placeholder="E-Mail" />
                        </div>
                        <div className="input-data">
                            <textarea required className="input-message" placeholder="Write your message here" />
                        </div>
                        <div className="input-data">
                            <button type="submit" className="form-submit-button">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact