import React from 'react'
import { useParams } from 'react-router-dom'
import Sale from './Services/Sale'
import Construction from './Services/Construction'
import Architecture from './Services/Architecture'
import Purchase_IN from './Services/Purchase_IN'
import Venture from './Services/Venture'
import Permits from './Services/Permits'
import Interior from './Services/Interior'
import Loan from './Services/Loan'
import Dubai from './Services/Dubai'
import Navbar from '../Navbar'
import Footer from '../Footer'

const Service = () => {
    const params = useParams()
    const serviceId = params.serviceId

    let Service
    switch (serviceId) {
        case "1":
            Service = Sale
            break
        case "2":
            Service = Construction
            break
        case "3":
            Service = Architecture
            break
        case "4":
            Service = Purchase_IN
            break
        case "5":
            Service = Venture
            break
        case "6":
            Service = Permits
            break
        case "7":
            Service = Interior
            break
        case "8":
            Service = Loan
            break
        case "9":
            Service = Dubai
            break
        default:
            Service = () => <div>Service not found</div>
    }
    return (
        <div>
            <Navbar />
            <div>
                <Service />
            </div>
        </div>
    )
}

export default Service