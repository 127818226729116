import "../../styles/Architecture.css"
import { addRecord } from "../../../config.js"

const Architecture = () => {
    const handleSubmit = (e) => {
        e.preventDefault()
        var formData = {
            type: document.querySelector('input[name="architecture"]:checked').value,
            name: document.querySelector('.name-input').value,
            email: document.querySelector('.email-input').value,
            phone: document.querySelector('.number-input').value,
            location: document.querySelector('.location-input').value,
            details: document.querySelector('.details-input').value
        }
        try {
            addRecord(formData, 'architecture')
        } catch (e) {
            console.log(e, "Error adding record to database.")
        }
        document.querySelector('.sale-form').reset()
    }
    return (
        <div>
            <div className="main-container">
                <div className="sale-content">
                    <h1>Architectural Assistance</h1>
                    <p>Elevate your architectural vision with our expert assistance! Choose from our specialized services to bring your project to fruition.</p>

                    <h2>Project Planning</h2>

                    <p>Unleash the full potential of your project with our meticulous project planning services. Our experienced team will work closely with you to develop a comprehensive plan that aligns with your goals and aspirations. From conceptualization to detailed execution strategies, we've got you covered.</p>

                    <h2>Individual Residence or Commercial Planning</h2>

                    <p>Whether you're envisioning the perfect home or a dynamic commercial space, our tailored planning services cater to your unique needs. Our skilled architects will collaborate with you to design spaces that seamlessly blend functionality with aesthetics, ensuring a harmonious balance in every detail.</p>
                </div>
                <div className='form-content'>
                    <form onSubmit={handleSubmit} className='sale-form'>
                        <div className="radio-container">
                            <div className='architecture-radio-options'>
                                <input name='architecture' type='radio' id='Planning' value='Planning' className='radio-selector' required />
                                <label htmlFor='Planning'>PLANNING</label>
                                <input name='architecture' type='radio' id='Residential' value='Residential' required />
                                <label htmlFor='Residential'>RESIDENTIAL</label>
                                <input name='architecture' type='radio' id='Commercial' value='Commercial' required />
                                <label htmlFor='Commercial'>COMMERCIAL</label>
                            </div>
                        </div>
                        <input
                            className='name-input'
                            type="text"
                            name="name"
                            required
                            placeholder='Full Name'
                        />
                        <input
                            className='email-input'
                            type="email"
                            name="email"
                            placeholder='Email Address'
                        />
                        <input
                            className='number-input'
                            type="number"
                            name="phone"
                            required
                            placeholder='Phone Number'
                        />
                        <input
                            className='location-input'
                            type="text"
                            name="location"
                            required
                            placeholder='Location'
                        />
                        <textarea
                            className='details-input'
                            name="details"
                            required
                            placeholder='Details'
                        />
                        <button type='submit' className='form-submit-button'>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Architecture