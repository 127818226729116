import { initializeApp } from "firebase/app";
import { getFirestore, setDoc, doc } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyB-TsTC6Typbe5L7IUKIrNzx770y37IAt0",
    authDomain: "property-bhopal-v2-3f4d7.firebaseapp.com",
    databaseURL: "https://property-bhopal-v2-3f4d7-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "property-bhopal-v2-3f4d7",
    storageBucket: "property-bhopal-v2-3f4d7.appspot.com",
    messagingSenderId: "688057583822",
    appId: "1:688057583822:web:b88d088f85bd92bc77289f",
    measurementId: "G-XDQPP614XD"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const addRecord = async (data, category) => {
    try {
        var date = new Date().getDate() + '-' + new Date().getMonth() + '-' + new Date().getFullYear() + ' ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() + ' ' + new Date().getMilliseconds()
        await setDoc(doc(db, category, date.toString()), data)
        alert('We will contact you soon. Thank you for your interest.')
    } catch (e) {
        console.log(e, "Error adding record to database.")
    }
}
