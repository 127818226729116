import "../../styles/Sale.css"
import { addRecord } from "../../../config.js"

const Sale = () => {
    const submitForm = (e) => {
        e.preventDefault()
        var formData = {
            condition: document.querySelector('input[name="condition"]:checked').value,
            type: document.querySelector('input[name="type"]:checked').value,
            name: document.querySelector('.name-input').value,
            email: document.querySelector('.email-input').value,
            phone: document.querySelector('.number-input').value,
            location: document.querySelector('.location-input').value,
            details: document.querySelector('.details-input').value
        }
        try {
            addRecord(formData, 'sale')
        } catch (e) {
            console.log(e, "Error adding record to database.")
        }
        document.querySelector('.sale-form').reset()
    }
    return (
        <div >
            <div className='main-container'>
                <div className='sale-content'>
                    <h1>Sell Any Property Hassle Free</h1>
                    <p>Unlock a stress-free path to selling your property with our dedicated services. Whether you're looking to sell a residential masterpiece, a commercial asset, or a vacant plot, we've got you covered. At Property Bhopal, we specialize in making the selling process smooth, efficient, and rewarding.</p>

                    <p>Our experienced team is committed to understanding your unique property and tailoring a strategy to maximize its market potential. From strategic pricing to targeted marketing, we ensure your property stands out in the competitive real estate landscape.</p>

                    <p>Say goodbye to complications and hello to hassle-free property sales. Connect with us today, and let's navigate the journey of selling your property together. Your stress-free selling experience begins here!</p>
                </div>
                <div className='form-content'>
                    <form className='sale-form' onSubmit={submitForm} method='POST'>
                        <div className="radio-container">
                            <div className='radio-options'>
                                <input name='condition' type='radio' id='New' value='New' className='radio-selector' required />
                                <label htmlFor='New'>NEW</label>
                                <input name='condition' type='radio' id='Used' value='Used' required />
                                <label htmlFor='Used'>USED</label>
                            </div>
                            <div className='radio-options'>
                                <input name='type' type='radio' id='Apartment' value='Apartment' required />
                                <label htmlFor='Apartment'>APARTMENT</label>
                                <input name='type' type='radio' id='Flat' value='Flat' required />
                                <label htmlFor='Flat'>FLAT</label>
                                <input name='type' type='radio' id='Land' value='Land' required />
                                <label htmlFor='Land'>LAND</label>
                            </div>
                        </div>
                        <input
                            className='name-input'
                            type="text"
                            name="name"
                            required
                            placeholder='Full Name'
                        />
                        <input
                            className='email-input'
                            type="email"
                            name="email"
                            placeholder='Email Address'
                        />
                        <input
                            className='number-input'
                            type="number"
                            name="phone"
                            required
                            placeholder='Phone Number'
                        />
                        <input
                            className='location-input'
                            type="text"
                            name="location"
                            required
                            placeholder='Location'
                        />
                        <textarea
                            className='details-input'
                            name="details"
                            required
                            placeholder='Details'
                        />
                        <button type='submit' className='form-submit-button'>Submit</button>
                    </form>
                </div>
            </div>
        </div >
    )
}

export default Sale