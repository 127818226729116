import { addRecord } from "../../../config"
import "../../styles/Venture.css"


const Sale = () => {
    const handleSubmit = (e) => {
        e.preventDefault()
        var formData = {
            name: document.querySelector('.name-input').value,
            email: document.querySelector('.email-input').value,
            phone: document.querySelector('.number-input').value,
            details: document.querySelector('.details-input').value
        }
        try {
            addRecord(formData, 'venture')
        } catch (e) {
            console.log(e, "Error adding record to database.")
        }
        document.querySelector('.sale-form').reset()
    }

    return (
        <div>
            <div className="main-container">
                <div className="sale-content">
                    <h1>Joint Venture</h1>
                    <p>Unlock unparalleled opportunities with our versatile real estate solutions, offering the flexibility of joint ventures or outright purchases. Tailor your investment strategy with the following options.</p>

                    <p>Delve into the possibilities of joint ventures or outright acquisitions for prime lands or plots. Whether you seek to develop a project collaboratively or acquire a property for exclusive ownership, we have diverse options to suit your vision.</p>

                    <p>Choose your desired location strategically. Our portfolio spans a range of regions, from urban centers to scenic landscapes. Select the backdrop that aligns with your project goals, ensuring a location that complements your vision.</p>

                    <p>Assess the market demand and potential for your investment. We provide comprehensive insights into the current and future demand dynamics, empowering you to make informed decisions for joint ventures or outright purchases that align with market trends.</p>
                </div>
                <div className='form-content'>
                    <form onSubmit={handleSubmit} className='sale-form venture'>
                        <input
                            className='name-input'
                            type="text"
                            name="name"
                            required
                            placeholder='Full Name'
                        />
                        <input
                            className='email-input'
                            type="email"
                            name="email"
                            placeholder='Email Address'
                        />
                        <input
                            className='number-input'
                            type="number"
                            name="phone"
                            required
                            placeholder='Phone Number'
                        />
                        <textarea
                            className='details-input'
                            name="details"
                            required
                            placeholder='Details'
                        />
                        <button type='submit' className='form-submit-button'>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Sale