import { addRecord } from "../../../config"
import "../../styles/Purchase.css"


const Permits = () => {
    const handleSubmit = (e) => {
        e.preventDefault()
        var formData = {
            condition: document.querySelector('input[name="condition"]:checked').value,
            type: document.querySelector('input[name="type"]:checked').value,
            name: document.querySelector('.name-input').value,
            email: document.querySelector('.email-input').value,
            phone: document.querySelector('.number-input').value,
            location: document.querySelector('.location-input').value,
            details: document.querySelector('.details-input').value
        }
        try {
            addRecord(formData, 'purchase')
        } catch (e) {
            console.log(e, "Error adding record to database.")
        }
        document.querySelector('.sale-form').reset()
    }

    return (
        <div>
            <div className="main-container">
                <div className="sale-content">
                    <h1>Purchase Property : Your Dream Space Just a Click Away</h1>
                    <p>Experience the ease of purchasing your dream property, be it in the heart of your nation or across international borders. Our services cater to a diverse range of preferences and locations, ensuring a seamless buying experience. Choose from the following options:</p>

                    <p>Explore a variety of residential options, including apartments, flats, or plots of land. Whether you're looking for a modern urban dwelling or a serene piece of land to build your vision, we have a diverse range to suit your needs.</p>

                    <p>Select your preferred location with precision. From bustling city centers to tranquil suburban landscapes, our offerings span a spectrum of choices.</p>

                    <p>Define your budget range to streamline the search for your ideal property.</p>
                    <p>For those with a specific interest in the vibrant city of Dubai, our specialized services cater to the unique real estate landscape in this cosmopolitan hub.</p>
                </div>
                <div className='form-content'>
                    <form onSubmit={handleSubmit} className='sale-form' method='POST'>
                        <div className="radio-container">
                            <div className='radio-options'>
                                <input name='condition' type='radio' id='New' value='New' className='radio-selector' required />
                                <label htmlFor='New'>NEW</label>
                                <input name='condition' type='radio' id='Used' value='Used' required />
                                <label htmlFor='Used'>USED</label>
                            </div>
                            <div className='radio-options'>
                                <input name='type' type='radio' id='Apartment' value='Apartment' required />
                                <label htmlFor='Apartment'>APARTMENT</label>
                                <input name='type' type='radio' id='Flat' value='Flat' required />
                                <label htmlFor='Flat'>FLAT</label>
                                <input name='type' type='radio' id='Land' value='Land' required />
                                <label htmlFor='Land'>LAND</label>
                            </div>
                        </div>
                        <input
                            className='name-input'
                            type="text"
                            name="name"
                            required
                            placeholder='Full Name'
                        />
                        <input
                            className='email-input'
                            type="email"
                            name="email"
                            placeholder='Email Address'
                        />
                        <input
                            className='number-input'
                            type="number"
                            name="phone"
                            required
                            placeholder='Phone Number'
                        />
                        <input
                            className='location-input'
                            type="text"
                            name="location"
                            required
                            placeholder='Location'
                        />
                        <textarea
                            className='details-input'
                            name="details"
                            required
                            placeholder='Details'
                        />
                        <button type='submit' className='form-submit-button'>Submit</button>
                    </form>
                </div>
            </div>
        </div >
    )
}

export default Permits