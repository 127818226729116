import "../../styles/Interior.css"
import { addRecord } from "../../../config"

const Interior = () => {
    const handleSubmit = (e) => {
        e.preventDefault()
        var formData = {
            name: document.querySelector('.name-input').value,
            email: document.querySelector('.email-input').value,
            phone: document.querySelector('.number-input').value,
            location: document.querySelector('.location-input').value,
            details: document.querySelector('.details-input').value
        }
        try {
            addRecord(formData, 'interior')
        } catch (e) {
            console.log(e, "Error adding record to database.")
        }
        document.querySelector('.sale-form').reset()
    }

    return (
        <div>
            <div className="main-container">
                <div className="sale-content">
                    <h1>Interior Design Services</h1>
                    <p>Elevate your living spaces with our comprehensive Planning and Interior Designing services. Tailored to your preferences, choose from a range of options to create the perfect ambiance for your home:</p>

                    <p>Whether you're moving into a new space or revitalizing a used one, our Planning and Interior Designing services are adaptable to your needs. From a blank canvas to a space with history, we specialize in bringing out the best in every setting.</p>

                    <p>Explore customized interior solutions for unique spaces and specific requirements. Our team is ready to tackle any design challenge, ensuring that your interior spaces are a true reflection of your personality and preferences.</p>
                </div>
                <div className='form-content'>
                    <form onSubmit={handleSubmit} className='sale-form'>
                        <input
                            className='name-input'
                            type="text"
                            name="name"
                            required
                            placeholder='Full Name'
                        />
                        <input
                            className='email-input'
                            type="email"
                            name="email"
                            placeholder='Email Address'
                        />
                        <input
                            className='number-input'
                            type="number"
                            name="phone"
                            required
                            placeholder='Phone Number'
                        />
                        <input
                            className='location-input'
                            type="text"
                            name="location"
                            required
                            placeholder='Location'
                        />
                        <textarea
                            className='details-input'
                            name="details"
                            required
                            placeholder='Details'
                        />
                        <button type='submit' className='form-submit-button'>Submit</button>
                    </form>
                </div>
            </div>
        </div >
    )
}

export default Interior