import React from "react";
import Card from "./Card";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";
import "../styles/Services.css"

const Services = () => {
    const servicesData = [
        {
            id: 1,
            title: 'Sale',
            description: 'Comprehensive property sales services for all types of properties.',
            imageUrl: "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.vecteezy.com%2Fpng%2F14455877-illustration-of-sale-icon-png-on-transparent-background&psig=AOvVaw2u9y3dtMbIXLAorRhOMGcp&ust=1707776796390000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCLi1oLyqpIQDFQAAAAAdAAAAABAE"
        },
        {
            id: 2,
            title: 'Construction',
            description: 'Full-range construction services for residential and commercial projects',
        },
        {
            id: 3,
            title: 'Architectural Assistance',
            description: 'Comprehensive architectural assistance providing innovative design solutions.',
        },
        {
            id: 4,
            title: 'Purchase National, International',
            description: 'Seamless property acquisition services catering to both domestic and international markets.',
        },
        {
            id: 5,
            title: 'Joint Venture or Outright',
            description: 'Tailored solutions for joint ventures and outright purchases.',
        },
        {
            id: 6,
            title: 'Government Approvals and NOCs',
            description: 'Efficient facilitation of government permits, including RERA compliance.',
        },
        {
            id: 7,
            title: 'Planning and Interior Designing',
            description: 'Innovative planning and bespoke interior design solutions.',
        },
        {
            id: 8,
            title: 'Loans and Finance',
            description: 'Comprehensive loan services offering tailored financial solutions and expert guidance.',
        },
        {
            id: 9,
            title: 'Invest In Dubai',
            description: "Specialized services facilitating strategic investments in Dubai's thriving real estate market.",
        },
        // Add more service data as needed
    ];

    return (
        <div>
            <Navbar />
            <h1 className="services-title">Services</h1>
            <div className="cards-container">
                {servicesData.map((service) => (
                    <Link to={`/services/${service.id}`}>
                        <Card
                            key={service.id}
                            title={service.title}
                            description={service.description}
                            imageUrl={service.imageUrl}
                        />
                    </Link>
                ))}
            </div>
        </div >
    )
}

export default Services;