import '../styles/Home.css'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { Link } from 'react-router-dom'

const Home = () => {
    return (
        <div className='home-page'>
            <Navbar />
            <div className="content">
                <section className='main-section'>
                    <div className="content-left">
                        <h1 className="section-title">Buy, Sell, Invest: Real Estate Services in Bhopal</h1>
                        <p className="section-description">Are you looking to make a strategic investment or find the perfect home, our real estate services are tailored to meet your specific needs. Bhopal, with its growing infrastructure and thriving communities, offers a promising landscape for real estate ventures. Trust our expertise to guide you through the process, making your real estate journey in Bhopal a rewarding experience.</p>
                        <div className="button-group">
                            <Link to='/services' className="services-button"> Get Services</Link>
                        </div>
                    </div>
                    <div className="content-right">
                        <div className="image-container">
                            <img src='/content-right-image.png'></img>
                        </div>
                    </div>
                </section>
                <section className='dubai-section'>
                    <div className="content-right">
                        <div className="image-container-dubai">
                            <img src='/dubai.jpg'></img>
                        </div>
                    </div>
                    <div className="content-dubai">
                        <h1 className="section-title-dubai">Explore Properties in Dubai</h1>
                        <p className="section-description-dubai">Explore premium properties in Dubai – where elegance meets innovation for the perfect living or investment experience.</p>
                        <div className="button-group">
                            <Link to='/services/9' className="services-button"> Explore</Link>
                        </div>
                    </div>

                </section>
                <div className="footer-container">
                    <div className="footer-copyright">
                        © 2024 Property Bhopal. All rights reserved.
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Home