import React from "react"
import Navbar from "../Navbar"
import Footer from "../Footer"
const About = () => {
    return (
        <div>
            <Navbar />
            <div className="about-us">
                <h1 >About Us</h1>
                <p>Welcome to Property Bhopal, where we redefine the art of real estate services. With a passion for transforming dreams into tangible properties, we are a dynamic team of industry experts committed to delivering unparalleled excellence.</p>

                <p>At Property Bhopal, we believe in more than just transactions – we believe in relationships. Our journey is rooted in understanding your unique needs, ensuring that every interaction is personalized and every property endeavor is a collaborative venture.</p>

                <p>What sets us apart is our unwavering commitment to integrity, transparency, and client satisfaction. Whether you're buying, selling, or investing, our seasoned professionals are here to guide you every step of the way. We combine industry knowledge, cutting-edge technology, and a client-centric approach to make your real estate experience seamless and rewarding.</p>

                <p>With a diverse portfolio of residential and commercial offerings, we cater to every aspiration. Our dedication extends beyond property transactions – we're invested in helping you find a place you'll proudly call home or make strategic investments that stand the test of time.</p>

                <p>Discover a new standard in real estate services with Property Bhopal. Let's embark on a journey of turning your real estate aspirations into reality. Your dream property is just a handshake away!</p>
            </div>
            <Footer />
        </div>
    )
}

export default About