import { addRecord } from "../../../config"
import "../../styles/Loan.css"

const Loan = () => {
    const handleSubmit = (e) => {
        e.preventDefault()
        var formData = {
            loan: document.querySelector('input[name="loan"]:checked').value,
            name: document.querySelector('.name-input').value,
            email: document.querySelector('.email-input').value,
            phone: document.querySelector('.number-input').value,
            location: document.querySelector('.location-input').value,
            details: document.querySelector('.details-input').value
        }
        try {
            addRecord(formData, 'loan')
        } catch (e) {
            console.log(e, "Error adding record to database.")
        }
        document.querySelector('.sale-form').reset()
    }

    return (
        <div>
            <div className="main-container">
                <div className="sale-content">
                    <h1>LOANS</h1>
                    <p>Unlock the doors to your dream home with our hassle-free Bank Loan services, specializing in Mortgage Loans and Housing Loans. Our tailored approach ensures a smooth and transparent process to secure the financing you need for your home.</p>

                    <h2>Mortgage Loan / Housing Loan</h2>

                    <p>Fulfill your homeownership aspirations with our Mortgage Loan and Housing Loan options. Whether you're purchasing a new property or refinancing an existing one, our financial experts will guide you through the intricacies of securing a loan, ensuring competitive interest rates and flexible repayment plans.</p>
                </div>
                <div className='form-content'>
                    <form onSubmit={handleSubmit} className='sale-form'>
                        <div className="radio-container">
                            <div className='loan-radio-options'>
                                <input name='loan' type='radio' id='mortgage' value='mortgage' className='radio-selector' required />
                                <label htmlFor='mortgage'>MORTGAGE LOAN</label>
                                <input name='loan' type='radio' id='Home' value='Home' required />
                                <label htmlFor='Home'>HOME LOAN</label>
                            </div>
                        </div>
                        <input
                            className='name-input'
                            type="text"
                            name="name"
                            required
                            placeholder='Full Name'
                        />
                        <input
                            className='email-input'
                            type="email"
                            name="email"
                            placeholder='Email Address'
                        />
                        <input
                            className='number-input'
                            type="number"
                            name="phone"
                            required
                            placeholder='Phone Number'
                        />
                        <input
                            className='location-input'
                            type="text"
                            name="location"
                            required
                            placeholder='Location'
                        />
                        <textarea
                            className='details-input'
                            name="details"
                            required
                            placeholder='Details'
                        />
                        <button type='submit' className='form-submit-button'>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Loan