import React from 'react';
import "../styles/Services.css";

const Card = ({ title, description }) => {
    return (
        <div>
            <div className="service-card">
                <div className="service-title">{title}</div>
                <p className="service-description">{description}</p>
            </div>
        </div>
    );
};

export default Card;
