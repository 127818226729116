import { addRecord } from "../../../config"
import "../../styles/Permits.css"

const Sale = () => {
    const handleSubmit = (e) => {
        e.preventDefault()
        var formData = {
            permit: document.querySelector('input[name="permit"]:checked').value,
            name: document.querySelector('.name-input').value,
            email: document.querySelector('.email-input').value,
            phone: document.querySelector('.number-input').value,
            location: document.querySelector('.location-input').value,
            details: document.querySelector('.details-input').value
        }
        try {
            addRecord(formData, 'permit')
        } catch (e) {
            console.log(e, "Error adding record to database.")
        }
        document.querySelector('.sale-form').reset()
    }
    return (
        <div>
            <div className="main-container">
                <div className="sale-content">
                    <p>Navigating the regulatory landscape is a crucial aspect of any construction project. Our expert assistance in securing government permissions and NOCs (No Objection Certificates) streamlines this process for you. Specify your requirements with the following options:</p>

                    <h2>T&CP (Town and Country Planning)</h2>

                    <p>Secure approvals from the Town and Country Planning authorities seamlessly. Our team will guide you through the process, ensuring compliance with regulations and obtaining the necessary permissions for your construction project.</p>
                    <h2>Nagar Nigam</h2>

                    <p>Obtain the essential permissions and NOCs from Nagar Nigam (Municipal Corporation) effortlessly. We handle the intricacies of the municipal approval process, facilitating a smooth journey from application to approval for your construction endeavors.</p>
                    <h2>RERA (Real Estate Regulatory Authority)</h2>

                    <p>Ensure compliance with RERA regulations by obtaining the required permissions. Our experts will assist you in navigating the regulatory requirements and obtaining the necessary NOCs to meet RERA standards for your real estate projects.</p>

                    <p>Explore additional permissions and NOCs tailored to your specific project needs. Whether it's environmental clearances, fire safety approvals, or any other regulatory requirement, our team is equipped to handle diverse scenarios to ensure your project's legality.</p>
                </div>
                <div className='form-content'>
                    <form onSubmit={handleSubmit} className='sale-form'>
                        <div className="radio-container">
                            <div className='permit-radio-options'>
                                <input name='permit' type='radio' id='RERA' value='RERA' required />
                                <label htmlFor='RERA'>RERA</label>
                                <input name='permit' type='radio' id='TCP' value='TCP' required />
                                <label htmlFor='TCP'>T & CP</label>
                                <input name='permit' type='radio' id='Nagar' value='Nagar' required />
                                <label htmlFor='Nagar'>NAGAR NIGAM</label>
                            </div>
                        </div>
                        <input
                            className='name-input'
                            type="text"
                            name="name"
                            required
                            placeholder='Full Name'
                        />
                        <input
                            className='email-input'
                            type="email"
                            name="email"
                            placeholder='Email Address'
                        />
                        <input
                            className='number-input'
                            type="number"
                            name="phone"
                            required
                            placeholder='Phone Number'
                        />
                        <input
                            className='location-input'
                            type="text"
                            name="location"
                            required
                            placeholder='Location'
                        />
                        <textarea
                            className='details-input'
                            name="details"
                            required
                            placeholder='Details'
                        />
                        <button type='submit' className='form-submit-button'>Submit</button>
                    </form>
                </div>
            </div>
        </div >
    )
}

export default Sale