import "../../styles/Dubai.css"
import { Link } from 'react-router-dom'
import { addRecord } from "../../../config"

const Dubai = () => {
    const handleSubmit = (e) => {
        e.preventDefault()
        var formData = {
            name: document.querySelector('.name-input').value,
            email: document.querySelector('.email-input').value,
            phone: document.querySelector('.number-input').value,
            location: document.querySelector('.location-input').value,
            details: document.querySelector('.details-input').value
        }
        try {
            addRecord(formData, 'dubai')
        } catch (e) {
            console.log(e, "Error adding record to database.")
        }
        document.querySelector('.sale-form').reset()
    }
    return (
        <div>
            <div className="main-container">
                <div className="sale-content">
                    <h1>Explore Properties in DUBAI</h1>
                    <p>Explore unparalleled investment opportunities in the vibrant real estate market of Dubai with our curated options from renowned developers.</p>

                    <h2 ><Link to='https://www.damacproperties.com/en/' target='_blank'>Damac Properties<img src='/share.png' alt="Visit Damac Properties"></img></Link> </h2>

                    <p>Immerse yourself in luxury and innovation with Damac Properties, a leading developer in Dubai's real estate landscape. Known for their iconic projects, Damac Properties offers a diverse range of residential and commercial properties, promising not just a space but a lifestyle.</p>
                    <h2><Link to='https://www.emaar.com/' target='_blank'>Emaar Properties <img src='/share.png' alt="Visit Emaar Properties"></img></Link></h2>

                    <p>Elevate your investment portfolio with Emaar Properties, a trailblazer in creating world-class communities. Emaar is synonymous with iconic landmarks, residential developments, and commercial spaces that redefine luxury and modern living. Invest with confidence in projects that embody excellence and sophistication.</p>
                </div>
                <div className='form-content dubai'>
                    <form onSubmit={handleSubmit} className='sale-form'>
                        <input
                            className='name-input'
                            type="text"
                            name="name"
                            required
                            placeholder='Full Name'
                        />
                        <input
                            className='email-input'
                            type="email"
                            name="email"
                            placeholder='Email Address'
                        />
                        <input
                            className='number-input'
                            type="number"
                            name="phone"
                            required
                            placeholder='Phone Number'
                        />
                        <input
                            className='location-input'
                            type="text"
                            name="location"
                            placeholder='Location'
                        />
                        <textarea
                            className='details-input'
                            name="details"
                            required
                            placeholder='Details'
                        />
                        <button type='submit' className='form-submit-button'>Submit</button>
                    </form>
                </div>
            </div>
        </div >
    )
}

export default Dubai