import '../../styles/Construction.css'
import { addRecord } from '../../../config.js'

const Construction = () => {
    const handleSubmit = (e) => {
        e.preventDefault()
        var formData = {
            type: document.querySelector('input[name="type"]:checked').value,
            name: document.querySelector('.name-input').value,
            email: document.querySelector('.email-input').value,
            phone: document.querySelector('.number-input').value,
            location: document.querySelector('.location-input').value,
            details: document.querySelector('.details-input').value
        }
        try {
            addRecord(formData, 'construction')
        } catch (e) {
            console.log(e, "Error adding record to database.")
        }
        document.querySelector('.sale-form').reset()
    }
    return (
        <div>
            <div className="main-container">
                <div className="sale-content">
                    <h1>Designing Dreams: Your Vision, Our Construction Canvas</h1>
                    <p>
                        Embark on your dream construction project with us! Whether it's the creation of your dream home or a cutting-edge commercial space, our full-fledged construction services are tailored to bring your vision to life. Backed by a team of seasoned professionals, we are your dedicated partners in crafting spaces that inspire.
                    </p>
                    <p>
                        At Property Bhopal, we are committed to excellence, ensuring top-notch construction quality at the most affordable rates. Beyond standard construction, we specialize in additional services such as Electrification and Overhead Water Tank Construction, adding a comprehensive touch to your project.
                    </p>
                    <p>
                        Ready to turn your vision into reality? Connect with us by filling out the form below. Let's build something extraordinary together!
                    </p>
                </div>
                <div className="form-content">
                    <div className="radio-container">
                        <div className='construction-radio-options'>
                            <input name='type' type='radio' id='House' value='House' className='radio-selector' required />
                            <label htmlFor='House'>Individual House</label>
                            <input name='type' type='radio' id='Residential' value='Residential' required />
                            <label htmlFor='Residential'>Residential Project</label>
                            <input name='type' type='radio' id='Commercial' value='Commercial' required />
                            <label htmlFor='Commercial'>Commercial Project</label>
                            <input name='type' type='radio' id='Services' value='Services' required />
                            <label htmlFor='Services'>Other Services</label>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className='sale-form'>
                        <input
                            className='name-input'
                            type="text"
                            name="name"  
                            required
                            placeholder='Full Name'
                        />
                        <input
                            className='email-input'
                            type="email"
                            name="email"
                            placeholder='Email Address'
                        />
                        <input
                            className='number-input'
                            type="number"
                            name="phone"
                            required
                            placeholder='Phone Number'
                        />
                        <input
                            className='location-input'
                            type="text"
                            name="location"
                            required
                            placeholder='Location'
                        />
                        <textarea
                            className='details-input'
                            name="details"
                            required
                            placeholder='Details'
                        />
                        <button type='submit' className='form-submit-button'>Submit</button>
                    </form>
                </div>
            </div >
        </div >
    )
}

export default Construction