import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../components/styles/Navbar.css'

const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false)

    const toggleMenu = () => {
        setShowMenu(!showMenu)
    }
    return (
        <div>
            <div className='header'>
                <Link to='/'>
                    <div className='logo'>
                        <img src='/logo_final.png' alt='logo' className='logo-icon' />
                        <div className='name'>
                            <p className='property'>PROPERTY</p>
                            <p className='bhopal'>BHOPAL</p>
                        </div>
                    </div>
                </Link>
                <nav className='nav'>
                    <Link to='/' className='nav-link'>Home</Link>
                    <Link to='/about' className='nav-link'>About</Link>
                    <Link to='/services' className='nav-link'>Services</Link>
                </nav>
                <Link to='/contact' className='contact-button'>Contact Us</Link>
            </div>
            <div className='header-small-screen'>
                <Link to='/'>
                    <div className='logo'>
                        <img src='/logo_final.png' alt='logo' className='logo-icon' />
                        <div className='name'>
                            <p className='property'>PROPERTY</p>
                            <p className='bhopal'>BHOPAL</p>
                        </div>
                    </div>
                </Link>
                <div className="small-nav-container">
                    <button className={'menu-button'} onClick={toggleMenu}>
                        <img src="/burger.svg" alt="menuButton" className='menu-icon'></img>
                    </button>
                    <nav className={'nav-small-screen-' + showMenu}>
                        <Link to='/' className='nav-link'>Home</Link>
                        <Link to='/about' className='nav-link'>About</Link>
                        <Link to='/services' className='nav-link'>Services</Link>
                        <Link to='/contact' className='contact-button'>Contact Us</Link>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default Navbar

